import { FC, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../Redux/store';
import { PaymentMethodType } from '../../../types';

interface PaymentSuccessfullProps {
   payment_source_type: any
}

const PaymentSuccessfull: FC<PaymentSuccessfullProps> = ({ payment_source_type }) => {
   const { doctor_country_currency_info, split_request_data } = useSelector((state: RootState) => state.paymentDetails);
   let currency =  (doctor_country_currency_info && doctor_country_currency_info.currency && doctor_country_currency_info.currency[0]) ?  doctor_country_currency_info.currency[0].display_logo : '$';
   const { token } = useParams<{ token: string }>();
   const navigate = useNavigate();
   useEffect(() => {
      if (split_request_data && Object.keys(split_request_data).length && token !== split_request_data['contract_patient_token']) {
         navigate('/invalid');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (<>
      <Container>
         <div className='payment-wrapper d-flex flex-column justify-content-between mxw-520 mt-5 text-center'>
            <div className='mt-5'>
            {(payment_source_type === PaymentMethodType.CARD || payment_source_type === PaymentMethodType.DEBIT) && 
               <img src='/assets/images/bigGreentick.svg' alt='' />
            }
            {payment_source_type === PaymentMethodType.BANK && 
               <img src='/assets/images/initiated.png' alt='' />
            }
            </div>
            {(payment_source_type === PaymentMethodType.CARD || payment_source_type === PaymentMethodType.DEBIT) && split_request_data?.treatment_amount &&
               <>
                  <h3 className="mt-2"> Payment of {currency}{split_request_data?.treatment_amount} is successfully Done! </h3>
                  <div style={{ color: '#42474D', fontSize: '18px', lineHeight: '25px' }}>
                     <div>Thank you for settling your account. </div>
                     <div className='mt-1'>We’ve received your payment. Your account is now marked as cleared.</div>
                  </div>
               </>}
            {payment_source_type === PaymentMethodType.BANK && split_request_data?.treatment_amount && <div>
               <>
                  <h3 className="mt-2"> Payment of {currency}{split_request_data?.treatment_amount} is initiated! </h3>
                  <div style={{ color: '#42474D', fontSize: '18px', lineHeight: '25px' }}>
                     <div>
                        Your payment has been successfully initiated. You will receive a confirmation once the transaction is processed within 2-5 business days.
                     </div>
                  </div>
               </>
            </div>}
            {(!split_request_data || !split_request_data?.treatment_amount) &&
               <>
                  <h3 className="mt-2"> Your payment has already been processed for this account </h3>
               </>
            }
         </div>
      </Container>
   </>)
}

export default PaymentSuccessfull;
