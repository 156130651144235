import React, { lazy, Suspense } from 'react';

const LazyRecoveryPlansComponent = lazy(() => import('./RecoveryPlansComponent'));

const RecoveryPlansComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyRecoveryPlansComponent {...props} />
  </Suspense>
);

export default RecoveryPlansComponent;
