import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Toaster } from "../../types";

interface ToasterInitialState {
    toaster: Toaster | null;
    error?: string | null;
    flag?: number | null;
    
  }

const initialState : ToasterInitialState = {
    toaster: null
}
const toasterSlice = createSlice({
    name: 'toasterSlice',
    initialState : initialState,
    reducers: {
        showToaster: (state, action: PayloadAction<Toaster>) => {
            state.toaster = {
              status: action.payload.status,
              message: action.payload.message,
              type: action.payload.type,
              duration: action.payload.duration
            }
          },
    }
})

export const { showToaster } = toasterSlice.actions;

export const ToasterReducer = toasterSlice.reducer;
