import React, { useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { default_toaster_values, Toaster, ToasterType } from '../../types';
import { AppDispatch } from '../../Redux/store';
import { showToaster } from '../../Redux/slices/toaster.slice';


export const NotificationToaster: React.FC<Toaster> = ({
  status=false,
  message,
  type = 'info',
  duration = 5000
}) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(showToaster(default_toaster_values));
    }, duration);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  if (!status) return null;

  const handleShowToaster = () => {
    dispatch(showToaster(default_toaster_values));
  }

  let header_title = type === ToasterType.Danger ? 'Error' : type;
  header_title = header_title.charAt(0).toUpperCase() + header_title.slice(1);

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast onClose={handleShowToaster} show={status} bg={type} autohide>
        <Toast.Header>
          <strong className="me-auto" >{header_title}</strong>
        </Toast.Header>
        <Toast.Body style={{color: type === ToasterType.Warning ? '#000' : "#FFF"}}>
        {typeof message === 'object' && message !== null 
          ? message?.message || ''
          : message
        }
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};