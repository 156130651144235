import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { responseType } from '../../service/shared.service';


export const ConfirmationModal = ({ isActive, onHide, paymentParams }: any) => {

    useEffect(() => {
        console.log(paymentParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = (resp?: string) => {
        onHide(resp);
    }

    return (
        <>
            <Modal show={isActive} onHide={() => handleClose(responseType.CLOSEMODAL)} centered>
                <Modal.Header closeButton className='p-4 px-5 border-0 align-items-start'>
                    <Modal.Title className='fs-24 fw-700'>Payment method added <br /> successfully.</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0 px-5'>
                    <div>
                        <span className='fs-18 fw-400 '>
                            Your today payment and upcoming recurring payments will be deducted from  **** {paymentParams['last4']}. You can set a different method for recurring.
                            {/* {paymentParams.payment_matrix_type} */}
                        </span>
                        {/* <span>
                            {paymentParams.last4}
                        </span> */}
                    </div>

                    <div className="d-flex justify-content-between align-items-center radius-8 mt-5" style={{background: '#EDFDF3', padding: '14px', border: 'solid 2px #18B557'}}>
                        <div className="d-flex flex-column">
                            <span className="fw-600 fs-16">Payment Method</span>
                            <span className="fw-400 fs-10 fst-italic" style={{color: "#797F87"}}>{paymentParams['card_type']}</span>
                        </div>
                        <div className='radius-8 d-flex' style={{background: 'white', padding: '10px', border: 'solid 1px #D0D5DD'}}>
                            <img src={'/assets/images/card-type/' + paymentParams['brand'] + '.svg'} style={{width : '28px'}} alt=''/>
                            <span className='fs-14 fw-600 mx-1' >{paymentParams['last4']}</span>
                        </div>
                    </div>
                    <div className='fs-18 fw-600 text-center text-primary mt-4'>
                        <span onClick = {() => handleClose(responseType.ADDNEW)} className='cursor'>+ Set new for recurring</span>
                    </div>
                </Modal.Body>

                <Modal.Footer onClick = {() => handleClose(responseType.KEEPSAME)} className='border-0 p-5 pt-2'>
                    <button className='btn btn-secondary w-100 p-3'>
                    Continue
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};