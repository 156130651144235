import { configureStore, combineReducers } from '@reduxjs/toolkit';
import paymentDetailsReducer from './slices/payment.slice';
import { LoaderReducer } from './slices/loader.slice';
import { ToasterReducer } from './slices/toaster.slice';

const rootReducer = combineReducers({
  paymentDetails: paymentDetailsReducer,
  loaderReducer: LoaderReducer,
  toaster: ToasterReducer
});

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['paymentDetails'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
  //     },
  //   }),
});

// export const persistor: Persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
