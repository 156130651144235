import { Button, Modal } from 'react-bootstrap';
import { patientPaymentSourceType, PaymentMethodType } from '../../types';
import { useEffect, useState } from 'react';
import { closeExisingPaymentSourceModal } from '../../service/shared.service';
import './ExistingPaymentSources.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
interface existingPaymentSourceProps {
    patient_sources: patientPaymentSourceType[], 
    isActive: boolean, 
    onHide: (type: string, data: any) => void, 
    patient_name: string
}

export const ExistingPaymentSources = ({patient_sources, isActive, onHide, patient_name}: existingPaymentSourceProps) => {
    // const dispatch = useDispatch<AppDispatch>();
    const [bankExist, setBankExist] = useState(false);
    const {patients} = useSelector((state: RootState) => state.paymentDetails || {});
    useEffect(() => {
        // console.log(paymentsCards);
        if (patients && patients.payment_sources && patients.payment_sources.length) {
            const filterItem = patients.payment_sources.filter(item => item.payment_source_type === PaymentMethodType.BANK);
            if (filterItem && filterItem.length) {
                setBankExist(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

      
    const [selectCard, setSelectCard] = useState<patientPaymentSourceType>();
    const handleClose = (type: string, data?: any) => {
        onHide(type, data)
    };

    return (
        <>
            <Modal show={isActive} onHide={() => handleClose(closeExisingPaymentSourceModal.CLOSE_MODAL)}>
                <Modal.Header closeButton className='p-4'>
                    <Modal.Title className='fs-18 fw-700'>Choose payment method</Modal.Title>
                </Modal.Header>
                {/* {JSON.stringify(patients?.payment_sources)} */}
                <Modal.Body className='p-4'>
                    {patients && patients.payment_sources && patients.payment_sources.length >=1  && patients.payment_sources.map((item: patientPaymentSourceType, index: number) => (
                        <div key={index} onClick={() => setSelectCard(item)}  className={`d-flex align-items-center p-3 radius-8 cursor  justify-content-between ${item === selectCard ? 'selected-card' : 'not-selected-card'}`}>
                            <span className='d-flex align-items-center'>
                           <img alt="" src={(item.payment_source_type === PaymentMethodType.CARD || item.payment_source_type === PaymentMethodType.DEBIT) ? '/assets/images/card-type/'+ ((item.brand + '').toLowerCase()) +'.svg' : '/assets/images/bank-icon.svg'} className='me-3' style={{width: '80px', height: '64px'}}/>
                           <span>
                                {/* <h4 className='fs-18 fw-600 m-0 pb-2'>{patient_name}</h4> */}
                                <span  className='fs-16 fw-500 sc'>****{item.last4}</span>
                            </span>
                            </span>
                            {item === selectCard ? (
                            <img src="/assets/images/green-tick-circle.svg" alt="" />
                            ): (<span className='radio-default'></span>)}
                        </div>
                    ))}
                    <div className='d-flex flex-column'>
                        <div onClick={() => {handleClose(closeExisingPaymentSourceModal.ADD_CARD)}} className='fs-18 fw-600 text-primary my-2 pb-2 cursor' style={{width: 'fit-content !important'}} >+ Add new card</div>
                        {!bankExist && <div onClick={() => {handleClose(closeExisingPaymentSourceModal.ADD_BANK)}} className='fs-18 fw-600 text-primary mb-2 pb-2 cursor' style={{width: 'fit-content !important'}} >+ Add Bank</div>}

                    </div>
                    <div className="d-flex p-3 radius-8 mb-4 bg-lightgrey">
                        <img src="/assets/images/shield-check.svg" alt="" className='me-3' />
                        <p className='fs-18 fw-600 m-0'>
                        We adhere entirely to the data security standards of the payment card industry.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4 justify-content-start'>
                    <Button disabled={!selectCard || (selectCard && !selectCard.last4)} variant="primary" className='btn btn-primary py-2 fw-700' onClick={() => handleClose(closeExisingPaymentSourceModal.SELECTED_CARD, selectCard?.source_id)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

