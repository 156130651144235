import React from "react";
import { Container } from "react-bootstrap";

const ErrorPage: React.FC = () => {
  return (
    <>
      <div className="h-100 d-flex flex-column justify-content-between bg-white">
          <div
            className="h-100 overflow-auto pt-md-5 p-3 p-md-4  container hide-scroll-bar"
            style={{ maxWidth: "1000px" }}>
          <div className="login-right-side flex-fill h-100 overflow-lg-auto">
            <Container className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
              <h1 style={{ color: "red" }}>Oops! Something went wrong.</h1>{" "}
              <p>We couldn't load the page. Please refresh or try again later.</p>{" "}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
