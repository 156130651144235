
const CopyRightFooter = () => {
  return (
    <>
    <div className="d-md-none bottom-0">
    <div className="copyright-footer w-100 w-100  border-top">
      <div className="container-xl d-flex flex-column-reverse flex-sm-row px-4 pt-2 pb-1 align-items-center justify-content-between ">
      <div className="text-center text-sm-center w-100">
          <p className='fs-12 m-0 text-center'>
          Powered by
            <img src="/assets/images/fm-logo.svg" alt="" className='hw-24 rounded mx-2 border d-sm-none text-center' />
              <img src="/assets/images/fm-logo.svg" alt="" className='hw-24 rounded mx-2 border d-none d-sm-inline-block' />
          <strong className='green-text'>Finance Mutual</strong></p>
        </div>
      </div>
    </div>
    <p className=" text-center copyright-text m-0 fs-14 fw-14">
        Copyright © 2025 Recurevy. All Rights Reserved.
      </p>
    </div>

    <div className="copyright-footer hw-65 w-100 w-100 d-none d-md-block border-top">
      <div className="container-xl d-flex flex-column-reverse flex-sm-row px-4 py-2  align-items-center justify-content-between ">
      <p className="copyright-text m-0 fs-14 fw-14">
        Copyright © 2025 Recurevy. All Rights Reserved.
      </p>
      <div className="text-center text-sm-end">
          <p className='fs-12 m-0'>
            <img src="/assets/images/fm-logo.svg" alt="" className='hw-24 rounded mx-2 border d-sm-none' /> <br className='d-sm-none' />
             Powered by <br className='d-block d-sm-none' />
              <img src="/assets/images/fm-logo.svg" alt="" className='hw-24 rounded mx-2 border d-none d-sm-inline-block' />
          <strong className='green-text'>Finance Mutual</strong></p>
        </div>
      </div>
    </div>
    </>
  );
};

export default CopyRightFooter;
