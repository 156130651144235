import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./DisputeDebt.scss";
// import { Button } from "react-bootstrap";
// import toastrSer from "../../../service/toast.service";
import { RootState } from "../../../Redux/store";
import { setLoading } from "../../../Redux/slices/loader.slice";
import httpApiCall, { uploadFile } from "../../../service/api.service";
import { base64ToBlob } from "../../../service/shared.service";
import CustomerDetails from "../CustomerDetails/CustomerDetails";
import ContinueFooter from "../../LayoutComponent/continueFooter/ContinueFooter";
import { showToaster } from "../../../Redux/slices/toaster.slice";
import { ToasterType } from "../../../types";

interface DisputeDebtProps {}

const DisputeDebt: FC<DisputeDebtProps> = () => {
  const steps = {
    DISPUTE_FORM: "DISPUTE_FORM",
    CUSTOMER_FORM: "CUSTOMER_FORM",
  };
  const { split_request_data } = useSelector(
    (state: RootState) => state.paymentDetails
  );
  const [reasons, setReasons] = useState<
    { reason_id: number; reason_name: string; selected: boolean }[]
  >([]);
  const [details, setDetails] = useState("");
  const [disputeData, setDisputeData] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [step, setStep] = useState<string>(steps.DISPUTE_FORM);

  // const toastr = new toastrSer(true);

  useEffect(() => {
    console.log(details, "details-----");

    if (
      !token ||
      !split_request_data ||
      (split_request_data && !split_request_data.treatment_amount)
    ) {
      navigate("/debt-recovery/" + token);
      return;
    }
    if (
      split_request_data &&
      Object.keys(split_request_data).length &&
      token !== split_request_data["contract_patient_token"]
    ) {
      navigate("/invalid");
      return;
    }
    async function callApi() {
      const params = {
        type: "GET" as "GET",
        body: {},
        api: "proposal/dispute_reason",
      };
      dispatch(setLoading(true));
      const response = await httpApiCall(params);
      dispatch(setLoading(false));

      if (!response.is_error && response.data && response.data.length) {
        response.data.map((item: any) => (item["selected"] = false));
        setReasons(response.data);
      } else {
        navigate("/invalid");
      }
    }

    callApi();
  }, []);

  const updateFunc = (index: number) => {
    const updatedReasons = reasons.map((item, idx) =>
      idx === index ? { ...item, selected: !item.selected } : item
    );
    setReasons(updatedReasons);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (files && files.length > 0) {
      const filePreviews: string[] = [];
      const validFiles: File[] = [];
      Array.from(files).forEach((file) => {
        const isDuplicate = uploadedFiles.some(
          (existingFile) =>
            existingFile.name === file.name && existingFile.size === file.size
        );

        if (!isDuplicate) {
          validFiles.push(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result) {
              filePreviews.push(reader.result as string);

              // Update state after processing all files
              if (filePreviews.length === validFiles.length) {
                setPreviewImages((prev) => [...prev, ...filePreviews]);
              }
            }
          };
          reader.readAsDataURL(file);
        } else {
          dispatch(showToaster({ message: "File already uploaded", type: ToasterType.Danger, status: true }));
          return;
        }
      });
      setUploadedFiles((prev) => [...prev, ...validFiles]);
    }
  };

  const removeImage = (index: number) => {
    setPreviewImages((prev) => prev.filter((_, i) => i !== index));
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const navigateToRoute = async () => {
    const selectedReasonIds = reasons.filter((item) => item.selected);
    if (
      !selectedReasonIds ||
      (selectedReasonIds && !selectedReasonIds.length)
    ) {
      // toastr.showError("Please select atleast one reason");
      dispatch(showToaster({ message: "Please select atleast one reason", type: ToasterType.Danger, status: true }));
      return;
    }
    if (!details || (details && !details.trim().length)) {
      // toastr.showError("Please enter details");
      dispatch(showToaster({ message: "Please enter details", type: ToasterType.Danger, status: true }));
      return;
    }

    if (!previewImages) {
      // toastr.showError("Please upload supporting document");
      dispatch(showToaster({ message: "Please upload supporting document", type: ToasterType.Danger, status: true }));
      return;
    }

    let formData = new FormData();
    for (let i = 0; i < previewImages.length; i++) {
      const blob = await base64ToBlob(previewImages[i]);
      formData.append(`file${i}`, blob);
    }

    const params = {
      type: "POST" as "POST",
      body: formData,
      api: "proposal/upload_debt_documents",
    };
    const uploadingFile = await uploadFile(params);
    console.log(uploadingFile);
    let ids: any = [];
    if (
      uploadingFile &&
      !uploadingFile.is_error &&
      uploadingFile.result &&
      uploadingFile.result.length
    ) {
      uploadingFile.result.map((item: any) => {
        ids.push(item.id);
      });
    }
    console.log(ids);
    const disputeData = {
      ids,
      selectedReasonIds,
      details,
    };
    setDisputeData(disputeData);
    setStep(steps.CUSTOMER_FORM);
    // navigate('/debt-recovery/customer-details/' + token)
  };

  const goBack = () => {
    navigate("/debt-recovery/" + token);
  };

  return (
    <>
      <div className="mxw-460 mx-auto disputeReasons">
        {step === steps.DISPUTE_FORM && (
          <div className="pt-5 px-2">
            <div className="header ">
              <p className="fw-500 fs-24 text-center">Dispute Your Debt</p>
            </div>
            <div className="underHeaderText">
              <p
                className="fs-16 fw-400 text-center"
                style={{ color: "#42474D", maxWidth: "416px" }}
              >
                If you believe this debt is incorrect or has been resolved, you
                can dispute it by providing details and supporting documents.
              </p>
            </div>
            <div>
              <label className="w-100">Reason for Dispute</label>
              {reasons.map(
                (
                  item: {
                    reason_id: number;
                    reason_name: string;
                    selected: boolean;
                  },
                  index: number
                ) => (
                  <div
                    key={index}
                    onClick={() => updateFunc(index)}
                    className={`${
                      item.selected ? "selected" : "notSelected"
                    } cursor reasons d-inline-block radius-16`}
                  >
                    {item.reason_name}
                  </div>
                )
              )}
            </div>

            <div className="my-3">
              <label className="w-100">Details of Dispute</label>
              <textarea
                className="detailsText form-control pt-2"
                style={{ height: "100px" }}
                onChange={($event) => setDetails($event.target.value)}
              />
            </div>

            <div>
              <h1 className="fs-16 fw-500">Upload Supporting Documents</h1>
              <p className="fs-14 fw-400 text-muted mxw-416">
                Upload proof, such as payment receipts, contracts, or
                correspondence
              </p>
            </div>

            <div className="my-3 ">
             <label htmlFor="file-upload" className="upload-btn">
                <span>+ Upload</span>
              </label>
              <input
                id="file-upload"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
                accept="image/*"
              />
              <div className="d-flex flex-wrap gap-4 mt-2">
                {previewImages.map((image, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <img
                      src={image}
                      alt={`Preview ${index}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <button
                      className="position-absolute"
                      onClick={() => removeImage(index)}
                      style={{
                        top: "0",
                        right: "0",
                        background: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {step === steps.CUSTOMER_FORM && (
        <CustomerDetails disputeData={disputeData} />
      )}

     { step === steps.DISPUTE_FORM && <ContinueFooter
        back={() => goBack()}
        onContinue={() => navigateToRoute()}
        disable={false}
        value="Continue"
      />}
    </>
  );
};

export default DisputeDebt;
