export type patientPaymentSourceType = {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: number;
    payment_source: string;
    payment_source_type: number;
    source_id: number;
    token: string;
    is_error: number
};

export const paymentMatrix = {
    source_id: 0,
    brand: '',
    exp_month: 0,
    exp_year: 0,
    fingerprint: '',
    token: 0,
    last4: 0,
    payment_source_type: 0
}

export interface paymentSourceType {
    primary: typeof paymentMatrix,
    backup: typeof paymentMatrix,
    recurring: typeof paymentMatrix
}
