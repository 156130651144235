import React, { lazy, Suspense } from 'react';

const LazyPayPartial = lazy(() => import('./PayPartial'));

const PayPartial = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPayPartial {...props} />
  </Suspense>
);

export default PayPartial;
