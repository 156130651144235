interface ContinueFooterProps {
  back: () => void;
  onContinue?: () => void;
  disable?: boolean;
  value: string;
  type?: any;
  form?: string;
}


const ContinueFooter: React.FC<ContinueFooterProps> =
  ({ back, onContinue, disable, value, type, form }) => {
    return (
      <footer className="footer-continue">
        <div className="payment-footer  w-100 p-md-4 py-3 border-top">
          <div className="d-flex justify-content-between align-items-center container ">
            <img onClick={back} className="cursor" src="/assets/images/back-circle.svg" alt=""
            />
            <button
              disabled={disable}
              className="btn btn-success fs-18 fw-500"
              style={{ backgroundColor: "#279F63" }}
              onClick={onContinue}
              type={type}
              form={form}
            >
              {value}
            </button>
          </div>
        </div>
      </footer>
    );
  };

export default ContinueFooter;
