import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Redux/store.tsx';
import RouterComponent from './routes';
// import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/error/Error.Boundry.component.tsx';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary>
  <Provider store={store}>
        <App />
        <RouterComponent />
      {/* <PersistGate loading={null} persistor={persistor}>
      </PersistGate> */}
  </Provider>
  </ErrorBoundary>
);

